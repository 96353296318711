<template>
  <div class="flex-col">
    <imgHeader title="关于我们" msg="ABOUT US" img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/dd177d09-dba5-432b-98c2-e6d41cb86a41699pic_d879b9e8220c0927ca22d2f098916439_300076569@2x.png" v-if="device === 'PC'" />
    <imgHeader
      title="关于我们"
      msg="ABOUT US"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/940e55de-87b5-4a13-a700-3ab2c52ce7b4699pic_d879b9e8220c0927ca22d2f098916439_300076569@3x.png"
      v-if="device === 'mobile'"
    />
    <div class="content">
      <switchBtn
        class="switch-btn-component"
        v-for="item in list"
        :key="item.title"
        v-bind="item"
      />
      <div class="img-box">
        <img class="img" src="./assets/img/bg-img.jpg" alt="" />
      </div>
      <p class="msg">
        安惠传媒是由来自网络营销专业以及拥有多年网络营销推广经验的精英团队组成,专注于提供一站式网络推广解决方案的互联网公司。为国内国际4A广告公司提供全网推广渠道服务。帮助企业在网络搭建并完善网络营销渠道、
        细化产品推介方式等。公司目前业务范围包括新闻营销、论坛营销、问答营销、百科营销、报纸软文营销、微博营销、微信营销，视频营销、媒体邀约、活动策划、海外推广、效果广告等一站式网络整合营销服务。免费量身定制一站式网络营销解决方案，欢迎广大新老客户前来洽谈合作！
      </p>
    </div>
    <myFooter />
  </div>
</template>
<script>
import myFooter from "../components/my-footer.vue";
import imgHeader from "../components/img-header.vue";
import switchBtn from "../components/switch-btn.vue";
export default {
  data() {
    return {
      list: [
        {
          title: "公司简介",
          url: "www.baidu.com",
        },
        {
          title: "企业文化",
          url: "www.baidu.com",
        },
        {
          title: "资质荣誉",
          url: "www.baidu.com",
        },
        {
          title: "发展历程",
          url: "www.baidu.com",
        },
        {
          title: "公司视频",
          url: "www.baidu.com",
        },
        {
          title: "团队展示",
          url: "www.baidu.com",
        },
      ],
    };
  },
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
  methods: {
  },
  components: {
    imgHeader,
    myFooter,
    switchBtn,
  },
};
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 480px) {
  .msg {
    text-indent: 2em;
    overflow-wrap: break-word;
    color: rgba(76, 76, 76, 1);
    font-size: 1.64rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 4.04rem;
  }
  .img-box {
    margin: 1.57rem 0 1.76rem;
  }
  .img {
    width: 100%;
  }
  .switch-btn-component {
    color: #000;
    width: 23%;
  }
  .content {
    padding: 3.2rem 1.1rem 4.1rem;
    background-color: #fff;
  }
}
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .msg {
    text-indent: 2em;
    overflow-wrap: break-word;
    color: rgba(76, 76, 76, 1);
    font-size: 0.64rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 1.574rem;
  }
  .img-box {
    margin: 1.57rem 0 1.76rem;
    width: 32rem;
    height: 12.53rem;
  }
  .img {
    width: 100%;
  }
  .switch-btn-component {
    color: #000;
  }
  .content {
    padding: 3.2rem 9.6rem 4.1rem;
    background-color: #fff;
  }
}
</style>
