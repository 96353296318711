<template>
  <div class="page flex-col">
    <imgHeader
      title="新闻资讯"
      msg="NEWS INFORMATION"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/3068af9f-4152-4fae-a8c9-0bca2f26729f矩形 1@2x (3).png"
      v-if="device === 'PC'"
    />
    <imgHeader
      title="新闻资讯"
      msg="NEWS INFORMATION"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/0b2db741-99c9-44da-8ee1-a1f4d399dc2b矩形 1@2x (10).png"
      v-else
    />
    <div class="content">
      <div class="btn-group">
        <switchBtn
          class="switch-btn-component"
          v-for="item in newsTypeList"
          :key="item.newTypeName"
          :title="item.newTypeName"
          @switch-click="switchClick(item.newsTypeId)"
        />
      </div>
      <div class="item-content">
        <div
          class="item flex-row justify-between"
          v-for="(item, index) in news"
          :key="item.newsCover + index"
          @click="navigateToDetails(item.newsId)"
        >
          <div class="left-img-box">
            <img class="img" :src="item.newsCover" alt="" />
          </div>
          <div class="right-box flex-row">
            <div class="date-box">
              <div class="year">{{ item.createTime.split('-')[0] }}</div>
              <div class="date">
                {{
                  item.createTime.split('-')[1] +
                  '/' +
                  item.createTime.split('-')[2]
                }}
              </div>
            </div>
            <div class="news-box">
              <div class="title-box">{{ item.newsTitle }}</div>
              <div class="msg-box">{{ item.newsContent }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="big-box-m">
        <div
          class="item flex-row justify-between"
          v-for="item in news"
          :key="item.newsId"
          @click="navigateToDetails(item.newsId)"
        >
          <div class="left-box">
            <img class="news-img" :src="item.newsCover" :alt="item.keywords" />
          </div>
          <div class="right-box">
            <div class="year">{{ item.createTime.split('-')[0] }}</div>
            <div class="date">
              {{
                item.createTime.split('-')[1] +
                '/' +
                item.createTime.split('-')[2]
              }}
            </div>
            <div class="title">{{ item.newsTitle }}</div>
            <div class="msg">
              {{ item.newsContent }}
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="changePage"
        :current-page="page"
        :page-size="5"
      ></el-pagination>
    </div>
    <myFooter />
  </div>
</template>
<script>
import myFooter from '../../components/my-footer.vue'
import imgHeader from '../../components/img-header.vue'
import switchBtn from '../../components/switch-btn.vue'
import { getNewsTypeList, getNewsListByType } from '@/api/index.js'
export default {
  data() {
    return {
      news: [],
      newsTypeList: [],
      type: 1,
      page: 1,
      total: 0,
    }
  },
  computed: {
    device() {
      return this.$store.state.userDevice
    },
  },
  methods: {
    navigateToDetails(newsId) {
      this.$router.push('/newsDetails?newsId=' + newsId)
    },
    switchClick(id) {
      this.type = id
      this.page = 1
      getNewsListByType(this.type).then(({ data }) => {
        this.news = data.newsList
        this.total = data.total
      })
    },
    changePage(pageNum) {
      this.page = pageNum
      getNewsListByType(this.type, pageNum).then(({ data }) => {
        this.news = data.newsList
        this.total = data.total
      })
    },
  },
  created() {
    getNewsTypeList().then(({ data }) => {
      this.newsTypeList = data.newsTypeList
    })

    getNewsListByType(1, 1).then(({ data }) => {
      this.news = data.newsList
      this.total = data.total
    })
  },
  components: {
    myFooter,
    imgHeader,
    switchBtn,
  },
}
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 480px) {
  .left-box,
  .right-box {
    height: 14.54rem;
    width: 24.18rem;
    overflow: hidden;
  }
  .right-box {
    background-color: #fff;
    padding: 1.23rem 1.03rem 0 1.58rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .big-box-m {
    display: flex;
    flex-direction: column;
  }

  .news-img {
    width: 100%;
  }

  .title {
    font-size: 1.64rem;
    margin-top: 1rem;
  }
  .msg {
    font-size: 1.03rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5a5a5a;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 可以显示的行数，超出部分用...表示*/
    -webkit-box-orient: vertical;
  }
  .year {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 1.23rem;
    font-family: MicrosoftYaHei;
    text-align: left;
  }
  .date {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 2.47rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid #a199a4;
    line-height: 2rem;
    padding-bottom: 1rem;
  }

  .item-content {
    display: none;
  }
  .item {
    margin-top: 0.82rem;
  }

  .right-box {
    box-shadow: 0px 1px 4px 2px rgba(7, 0, 2, 0.1);
  }

  .title-box {
    padding: 0rem 1.12rem 0 1.44rem;
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 0.8rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 1.014rem;
  }

  .switch-btn-component {
    box-shadow: 0px 1px 4px 1px rgba(7, 0, 2, 0.11);
  }

  .content {
    padding: 1.71rem 1.1rem 2.19rem;
  }
  .btn-group {
    margin-bottom: 1.01rem;
  }

  .img {
    width: 100%;
  }
  .pagination {
    /* padding: 0 22rem; */
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .big-box-m {
    display: none;
  }
  .title-box {
    padding: 0rem 1.12rem 0 1.44rem;
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 0.8rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 1.014rem;
  }
  .year {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 0.773rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 1.014rem;
  }

  .switch-btn-component {
    box-shadow: 0px 1px 4px 1px rgba(7, 0, 2, 0.11);
  }
  .date {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 1.04rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 1.014rem;
  }
  .date-box {
    padding: 0.85rem 0.99rem 1.41rem 1.25rem;
    width: 5.5rem;
    border-right: 1px solid #ccc;
  }
  .msg-box {
    padding: 0.4rem 1.12rem 0 1.44rem;
    width: 12.56rem;
    overflow-wrap: break-word;
    color: rgba(95, 95, 95, 1);
    font-size: 0.48rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 0.88rem;
    text-indent: 2em;
  }
  .item {
    box-shadow: 0px 8px 16px 4px rgba(7, 0, 2, 0.11);
    margin-bottom: 0.53rem;
  }
  .content {
    padding: 1.71rem 9.6rem 2.19rem;
  }
  .btn-group {
    margin-bottom: 1.01rem;
  }
  .left-img-box {
    width: 11.11rem;
    height: 6.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .img {
      width: 100%;
      max-height: 6rem;
    }
  }
  .right-box {
    width: 20.88rem;
    height: 6.75rem;
    padding: 1.5rem 1.12rem 1.09rem 1.65rem;
    overflow: hidden;
  }
  .img {
    width: 100%;
  }
  .pagination {
    /* padding: 0 22rem; */
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
</style>
