<template>
  <button class="switch-btn" @click="handleBtnClick">{{ title }}</button>
</template>
<script>
export default {
  name: "switch-btn",
  data() {
    return {};
  },
  props: {
    title: String,
  },
  methods: {
    handleBtnClick() {
      this.$emit("switch-click");
    },
  },
};
</script>
<style scoped>
.switch-btn {
  /* background-color: ; */
  width: 3.44rem;
  height: 1.26rem;
  border-radius: 0.63rem;
  /* color: #fff; */
  font-size: 0.53rem;
  margin-right: 0.5rem;
}
.switch-btn:hover {
  background-color: #ba00ff;
  color: #fff;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .switch-btn {
    /* background-color: ; */
    width: 8.44rem;
    height: 3.22rem;
    border-radius: 1.61rem;
    /* color: #fff; */
    font-size: 1.64rem;
  }
  .switch-btn:hover {
    background-color: #ba00ff;
    color: #fff;
  }
}
</style>
