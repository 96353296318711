<template>
  <div class="cehuaxiangmu flex-col">
    <item-title title="策划项目" msg="PLAN A PROJECT"></item-title>
    <div class="big-box">
      <div class="flex-col box" v-for="item in list" :key="item.imgUrl" >
        <div class="img-box">
          <img class="img" v-if="device === 'PC'" :src="item.projectCover" alt="" />
          <img
            class="img_m"
            v-if="device === 'mobile'"
            :src="item.projectCoverPhone"
            alt=""
          />
        </div>
        <div class="msg-box flex-col">
          <p class="title">{{ item.projectName }}</p>
          <p class="paragraph_3">{{ item.subProject }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemTitle from "./item-title.vue";
import { getProjectList } from "@/api/index.js";
export default {
  name: "cehuaxiangmu",
  data() {
    return {
      list: [],
    };
  },
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
  created() {
    getProjectList().then(({ data }) => {
      this.list = data.projectList;
    });
  },
  components: {
    itemTitle,
  },
};
</script>
<style scoped>
.msg-box {
  z-index: 9999;
  margin-left: 0.5rem;
}
.big-box {
  margin: 2.88rem 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.img-box {
  width: 5.6rem;
  position: absolute;
  top: 0;
  left: 0;
}
.img {
  width: 100%;
}
@keyframes big {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.07);
  }
}
@keyframes bigSize {
  from {
    font-size: 0.64rem;
  }
  to {
    font-size: 0.84rem;
  }
}
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .box:hover {
    animation-name: big;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
}
.cehuaxiangmu {
  padding: 2.03rem 9.6rem 4.4rem;
  /* background-color: #333; */
  background-color: #000;
}

.box {
  margin: 0 auto;
  flex: 0 1 auto;
  width: 5.6rem;
  height: 8.83rem;
  position: relative;
}

.title {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.64rem;
  font-weight: bold;
  font-family: MicrosoftYaHei-Bold;
  white-space: nowrap;
  line-height: 0.64rem;
  margin-top: 1.094rem;
}

.paragraph_3 {
  display: inline-block;
  width: 4.7rem;
  color: rgba(255, 255, 255, 1);
  font-size: 0.426rem;
  text-align: left;
  line-height: 0.7rem;
  margin-top: 0.587rem;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .cehuaxiangmu {
    padding: 5.41rem 1.1rem 4.4rem;
    background-color: #000;
  }
  .msg-box {
    z-index: 9999;
    margin-left: 0.5rem;
    padding: 3.36rem 0 0 1.41rem;
  }
  .big-box {
    margin: 5rem 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .box {
    margin: 0 auto 1.51rem;
    flex: 0 1 auto;
    width: 49.18rem;
    height: 17.26rem;
    position: relative;
  }

  .img-box {
    width: 49.18rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .img {
    width: 100%;
  }
  .img_m {
    width: 100%;
  }
  .title {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 2.47rem;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold;
    white-space: nowrap;
    line-height: 0.64rem;
  }

  .paragraph_3 {
    display: inline-block;
    width: 35.07rem;
    color: rgba(255, 255, 255, 1);
    font-size: 1.64rem;
    text-align: left;
    line-height: 3rem;
    margin-top: 0.587rem;
  }
}
</style>
