<template>
  <div class="zhuyingyewu flex-col">
    <item-title title="主营业务" msg="MAIN BUSINESS"></item-title>
    <div class="business-box">
      <div class="group_28 flex-col" v-for="item in businessList" :key="item.whiteImgUrl">
        <img class="item-img" :src="item.whiteImgUrl" />
        <span class="item-title">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import itemTitle from "./item-title.vue";
export default {
  name: "zhuyingyewu",
  data() {
    return {};
  },
  props: {
    businessList: Array,
  },
  components: {
    itemTitle,
  },
};
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .group_28 {
    /* width: 5.654rem; */
    width: 20%;
  }
}
.zhuyingyewu {
  /* background-color: rgba(26, 26, 26); */
  /* background: url(https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/1558feaf-2fc8-437b-96b2-bc5d969e3aa63.png) no-repeat; */
  background-size: 100% 100%;
  background-color: #000;
  padding-top: 2rem;
}
.business-box {
  margin: 2.24rem auto 2.24rem;
  display: flex;
  flex-wrap: wrap;
  width: 33rem;
  justify-content: space-between;
}

.group_28 {
  /* width: 5.654rem; */
  width: 12vw;
}

.item-img {
  width: 2.027rem;
  height: 2rem;
  margin: 0.854rem auto 0;
}

.item-title {
  height: 0.534rem;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.533rem;
  font-family: MicrosoftYaHei;
  text-align: left;
  white-space: nowrap;
  line-height: 0.534rem;
  margin: 1.2rem auto 1.21rem;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .zhuyingyewu {
    /* background-image: url(https://anhuiguanwang.oss-cn-shenzhen.aliyuncs.com/anhui/2022-11-28/2e28231f-dd08-4bb7-8aef-dc34fe3fa5ecbg.pic.jpg); */
    padding-top: 5rem;
  }
  .business-box {
    margin: 4.79rem auto 2.24rem;
    display: flex;
    flex-wrap: wrap;
    width: 33rem;
    justify-content: space-between;
  }

  .group_28 {
    /* width: 5.654rem; */
    width: 40%;
  }

  .item-img {
    width: 5.44rem;
    height: 5.44rem;
    margin: 0.854rem auto 0;
  }

  .item-title {
    height: 0.534rem;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.92rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 0.534rem;
    margin: 3.42rem auto 4.93rem;
  }
}
</style>
