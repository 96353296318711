<template>
  <div class="page">
    <imgHeader
      title="策划项目"
      msg="PLAN A PROJECT"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/7642aa2e-7946-4053-b77e-18e747cbdfa7矩形 1@2x (1).png"
      v-if="device === 'PC'"
    />
    <imgHeader
      title="策划项目"
      msg="MAIN BUSINESS"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/38ffbb7c-e3a1-4c61-bd4b-01f6aa7997fa矩形 1@2x (8).png"
      v-if="device === 'mobile'"
    />
    <div class="content" v-if="device === 'PC'">
      <div class="item-box" v-for="item in list" :key="item.title">
        <div class="img-box" :style="{ backgroundImage: `url(${item.projectCover2})` }">
          <div class="content-box">
            <div class="title">{{ item.projectName }}</div>
            <div class="msg">{{ item.subProject }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="big-box" v-if="device === 'mobile'">
      <div class="flex-col box" v-for="item in list" :key="item.imgUrl">
        <div class="img-box">
          <img class="img" v-if="device === 'PC'" :src="item.projectCover" alt="" />
          <img
            class="img_m"
            v-if="device === 'mobile'"
            :src="item.projectCoverPhone"
            alt=""
          />
        </div>
        <div class="msg-box flex-col">
          <div class="title">{{ item.projectName }}</div>
          <div class="paragraph_3">{{ item.subProject }}</div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>
<script>
import myFooter from "../components/my-footer.vue";
import imgHeader from "../components/img-header.vue";
import { getProjectList } from "@/api/index.js";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    getProjectList().then(({ data }) => {
      this.list = data.projectList;
    });
  },
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
  methods: {},
  components: {
    myFooter,
    imgHeader,
  },
};
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  html {
    font-size: 37.5px;
  }
  .content {
    padding: 1.04rem 9.6rem 0.4rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .item-box {
    height: 9.81rem;
    width: 15.58rem;
    margin-bottom: 0.83rem;
    background-color: #eee;
    position: relative;
  }
  .img-box {
    height: 9.81rem;
    width: 15.58rem;
    /* background-size: contain; */
    background-size: 15.58rem 9.81rem;
    padding: 2rem 4.4rem;
  }
  .title {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.96rem;
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    line-height: 0.96rem;
    margin: 0 0 1.44rem 0;
  }

  .msg {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.586rem;
    font-family: MicrosoftYaHei;
    text-align: center;
    line-height: 0.88rem;
  }

  .content-box {
    z-index: 33;
    color: #fff;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .cehuaxiangmu {
    padding: 5.41rem 1.1rem 4.4rem;
    background-color: #000;
  }
  .msg-box {
    z-index: 34;
    margin-left: 0.5rem;
    padding: 3.29rem 0 0 1.41rem;
  }
  .big-box {
    margin: 1.92rem 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .box {
    margin: 0 auto 1.51rem;
    flex: 0 1 auto;
    width: 49.18rem;
    height: 17.26rem;
    position: relative;
  }

  .img-box {
    width: 49.18rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .img {
    width: 100%;
  }
  .img_m {
    width: 100%;
  }
  .title {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 2.47rem;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold;
    white-space: nowrap;
  }

  .paragraph_3 {
    display: inline-block;
    width: 35.07rem;
    color: rgba(255, 255, 255, 1);
    font-size: 1.64rem;
    text-align: left;
    line-height: 3rem;
    margin-top: 0.587rem;
  }
}
</style>
