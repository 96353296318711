<template>
  <div class="header-img-box">
    <img class="header-img" :src="img" alt="" />
    <itemTitle class="itemTitle" :title="title" :msg="msg"></itemTitle>
  </div>
</template>
<script>
import itemTitle from "./../views/components/item-title.vue";
export default {
  name: "img-header",
  props: {
    img: String,
    title: String,
    msg: String,
  },
  methods: {
    add() {},
  },
  components: {
    itemTitle,
  },
};
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .itemTitle{
    position: absolute;
    z-index: 999;
    top:10rem;
    left: 18.2rem;
  }
  .header-img-box {
    position: relative;
    width: 100%;
  }
  .header-img {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .itemTitle{
    position: absolute;
    z-index: 999;
    top:16.85rem;
    left: 15.27rem;
  }
  .header-img-box {
    position: relative;
    height: 31.03rem;
  }
  .header-img {
    width: 100%;
  }
}
</style>
