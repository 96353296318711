<template>
  <div class="aboutUs flex-col" v-if="device === 'PC'">
    <item-title title="关于我们" msg="ABOUT US"></item-title>
    <div class="aboutus-box">
      <div class="left-img-box">
        <img class="img" src="https://s1.ax1x.com/2022/11/15/zEG7R0.png" alt="" />
      </div>
      <div class="right-text-box">
        <div class="msg">
          安惠传媒是由来自网络营销专业以及拥有多年网络营销推广经验的精英团队组成,专注于提供一站式网络推广解决方案的互联网公司。为国内国际4A广告公司提供全网推广渠道服务。帮助企业在网络搭建并完善网络营销渠道、细化产品推介方式等。公司目前业务范围包括新闻营销、论坛营销、问答营销、百科营销、报纸软文营销、微博营销、微信营销,视频营销、媒体邀约、活动策划、海外推广、效果广告等一站式网络整合营销服务。免费量身定制一站式网络营销解决方案,欢迎广大新老客户前来洽谈合作！
        </div>
        <div class="more-box">
          <div class="more flex-row justify-start">
            <router-link to="/aboutUs" class="tab1">MORE</router-link>
            <router-link to="/aboutUs" class="tab2">
              <i class="el-icon-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemTitle from "./item-title.vue";
export default {
  name: "about-us",
  components: {
    itemTitle,
  },
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
};
</script>
<style scoped>
.msg {
  text-indent: 2em;
}
.more {
  margin-top: 2rem;
  width: 4rem;
}
.tab1 {
  width: 2rem;
  color: white;
  font-size: 0.48rem;
  border-bottom: 3px solid #aa24fd;
}
.tab2 {
  width: 1rem;
  color: white;
  font-size: 0.48rem;
  border-bottom: 3px solid #fcb800;
}
.aboutUs {
  background-color: rgba(0, 0, 0, 1);
  padding-top: 1.76rem;
}

.aboutus-box {
  display: flex;
}

.img {
  width: 100%;
}
.left-img-box {
  width: 22.05rem;
  height: 18.93rem;
  /* background: #2e2e2e; */
  /* opacity: 0.23; */
}
.el-button--text {
  color: #fff !important;
}
.right-text-box {
  width: 16.934rem;
  /* height: 6.907rem; */
  padding-top: 2rem;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.48rem;
  font-family: MicrosoftYaHei;
  text-align: left;
  line-height: 1.067rem;
  margin: 2.03rem 0 0 1.76rem;
}
</style>
