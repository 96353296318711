import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
  state() {
    return {
      userDevice:''
    }
  },
  mutations: {
    setUserDevice(state) {
      console.log('setUserDevice')
      state.userDevice=/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
        ? "mobile"
        : "PC"
    }
  }
})

export default store