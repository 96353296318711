<template>
  <div>
    <my-swiper :swiperList="swiperList"></my-swiper>
    <about-us></about-us>
    <zhuyingyewu :businessList="businessList"></zhuyingyewu>
    <cehuaxiangmu></cehuaxiangmu>
    <youshi class="youshi"></youshi>
    <anli></anli>
    <news-information></news-information>
    <cooperation></cooperation>
    <my-footer></my-footer>
  </div>
</template>
<script>
import mySwiper from '../components/my-swiper.vue'
import myFooter from '../components/my-footer.vue'
import aboutUs from './components/about-us.vue'
import zhuyingyewu from './components/zhuyingyewu.vue'
import cehuaxiangmu from './components/cehuaxiangmu.vue'
import youshi from './components/youshi.vue'
import anli from './components/anli.vue'
import newsInformation from './components/news-information.vue'
import cooperation from './components/cooperation.vue'
import { getSwiperList, getBusinessList } from '@/api/index.js'
export default {
  data() {
    return {
      swiperList: [],
      businessList: [],
    }
  },
  beforeCreate() {
    let that = this
    getSwiperList().then(({ data }) => {
      that.swiperList = data.bannerList
    })
    getBusinessList().then(({ data }) => {
      that.businessList = data.businessList
    })
  },
  methods: {},
  components: {
    mySwiper,
    myFooter,
    aboutUs,
    zhuyingyewu,
    cehuaxiangmu,
    youshi,
    anli,
    newsInformation,
    cooperation,
  },
}
</script>
<style scoped>
div >>> .youshi {
  color: #fff;
}
</style>
