import Vue from 'vue'
import VueRouter from 'vue-router'
import caseShow from '../views/case-show/case-show.vue'
import advantage from '../views/advantage.vue'
import aboutUs from '../views/about-us.vue'
import lianxiwomen from '../views/lianxiwomen.vue'
import anhuiguanwang from '../views/index.vue'
import news from '../views/news/news.vue'
import zhuyingyewu from '../views/zhuyingyewu.vue'
import planProject from '../views/plan-project.vue'
import myBar from '../views/my-bar.vue'
import caseDetails from '../views/case-show/case-detail.vue'
import newsDetails from '../views/news/news-details.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/anhuiguanwang',
    redirect: "/anhuiguanwang"
  },
  {
    path: '/myBar',
    name: 'myBar',
    component: myBar
  },
  {
    path: '/advantage',
    name: 'advantage',
    component: advantage
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/lianxiwomen',
    name: 'lianxiwomen',
    component: lianxiwomen
  },
  {
    path: '/',
    name: 'anhuiguanwang',
    component: anhuiguanwang
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/zhuyingyewu',
    name: 'zhuyingyewu',
    component: zhuyingyewu
  },
  {
    path: '/planProject',
    name: 'planProject',
    component: planProject
  },
  {
    path: '/caseShow',
    name: 'caseShow',
    component: caseShow
  },
  {
    path: '/caseDetails',
    name: 'caseDetails',
    component: caseDetails
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: newsDetails
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,savedPosition) {
    console.log('savedPosition',savedPosition)
    console.log('to',to)
    console.log('from',from)
    if (to.hash) {
      return {
        x: 0,
        y: 0,
        selector: to.hash,
        behavior: 'smooth',
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
