<template>
  <div class="footer flex-col">
    <div class="section_20">
      <div class="text-wrapper_20" v-for="item in list" :key="item.title">
        <div class="paragraph_25">{{ item.title }}</div>
        <div class="paragraph_26">
          <div class="title" v-for="item2 in item.linkList" :key="item2">
            {{ item2 }}
          </div>
        </div>
      </div>
    </div>
    <div class="section_21">
      <div class="box flex-row justify-between">
        <div class="flex-col">
          <span class="text_65">联系我们</span>
          <span class="text_66">CONTACK US</span>
        </div>
        <div class="box_54">
          <div class="text_68">电话:12135323153431</div>
          <div class="text_68">邮箱:13253432158@qq.com</div>
          <div class="text_68">QQ:13253432158@qq.com</div>
        </div>
      </div>
      <div class="middle-box flex-row justify-between">
        <div class="left">
          <div class="text_65">留言</div>
          <div class="text_66">MESSAGE</div>
        </div>
        <div class="right flex-col justify-between">
          <div class="top-box flex-row justify-between">
            <input
              v-model="form.nickName"
              class="input"
              type="text"
              placeholder="昵称"
            />
            <input
              v-model="form.userPhone"
              class="input"
              type="text"
              placeholder="电话"
            />
          </div>
          <textarea
            v-model="form.message"
            class="textarea"
            type="textarea"
            placeholder="留言"
          />
          <el-button
            @click="submitForm"
            class="btn"
            placeholder="提交"
            :disabled="btnDisabled"
          >
            提交
          </el-button>
        </div>
      </div>
      <div class="image-text_4 flex-col">
        <img
          class="image_42"
          referrerpolicy="no-referrer"
          src="https://s1.ax1x.com/2022/11/04/xLNGb4.png"
        />
        <span class="text-group_12">关注公众号</span>
      </div>
    </div>
    <div class="bottom-box">
      <p class="text_78">
        Copyright © 2009-2021 深圳市安惠传媒科技有限公司 版权所有
        热线:4008-114-788粤ICP备13066397号
      </p>
    </div>
  </div>
</template>
<script>
import { addMessage } from '../api/index.js'
export default {
  name: 'my-footer',
  data() {
    return {
      btnDisabled: false,
      form: {
        nickName: null,
        userPhone: null,
        message: null,
      },
      list: [
        {
          title: '整合方案',
          linkList: [
            '品牌推广',
            '产品推广',
            '活动推广',
            '企业营销推广',
            '危机公关',
          ],
        },
        {
          title: '新闻软文营销',
          linkList: [
            '新闻发稿',
            '首页发稿',
            '海外发稿',
            '客户端发稿',
            '媒体邀约',
            '报纸发稿',
            '软文撰写',
          ],
        },
        {
          title: '口碑营销',
          linkList: [
            '论坛营销',
            '博客营销',
            '视频营销',
            '百度百科',
            '问答营销',
            '百度文库',
            '百度经验',
          ],
        },
        {
          title: '新媒体营销',
          linkList: [
            '微信营销',
            '微博营销',
            '朋友圈推广',
            'H5直播',
            '短视频',
            'APP推广',
          ],
        },
        {
          title: '效果广告',
          linkList: [
            '腾讯智汇推',
            '优酷睿视',
            '腾讯广点通',
            '爱奇艺',
            '陌陌',
            '新浪扶翼',
            'WIFI万能钥匙',
          ],
        },
      ],
    }
  },
  methods: {
    submitForm() {
      this.btnDisabled = true
      setTimeout(() => {
        this.btnDisabled = false
      }, 3000)
      if (this.form.username === '') {
        this.$message.error('请输入您的昵称')
      } else if (this.form.userPhone === '') {
        this.$message.error('请输入您的电话')
      } else if (this.form.message === '') {
        this.$message.error('请输入您的留言')
      } else {
        addMessage(this.form).then(({ data }) => {
          if (data.msg === '留言成功') {
            this.$message({
              message: '留言成功',
              type: 'success',
            })
          } else {
            this.$message.error(data.msg)
          }
        })
        this.form.message = ''
        this.form.nickName = ''
        this.form.userPhone = ''
      }
    },
  },
}
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .el-button {
    padding: 0 !important;
    border: none;
    border-radius: 0px;
  }
  .el-button:hover {
    background-color: #b701fb;
    color: #aaa;
  }
  .btn:disabled {
    background-color: #eee;
    color: #555;
  }
  .btn {
    background-color: #b701fb;
    color: #fff;
    font-size: 0.43rem;
  }
  .input {
    height: 1rem;
    font-size: 0.43rem;
    width: 4.5rem;
  }
  .textarea {
    margin: 0.1rem 0;
    width: 9.1rem;
    height: 2rem;
    font-size: 0.43rem;
    resize: none;
  }
  .middle-box {
    width: 12rem;
    height: 4rem;
  }
  .title {
    line-height: 1rem;
    height: 1rem;
  }
  .text_78 {
    color: rgba(255, 255, 255, 1);
    font-size: 0.426rem;
    text-align: center;
    line-height: 1rem;
  }

  .footer {
    /* background-color: rgba(28, 28, 28, 1); */
    background-color: #000;
    /* background: url(https://anhuiguanwang.oss-cn-shenzhen.aliyuncs.com/anhui/2022-11-28/2e28231f-dd08-4bb7-8aef-dc34fe3fa5ecbg.pic.jpg)
      no-repeat; */
    background-size: 100% 100%;
  }
  .section_20 {
    width: 32rem;
    margin: 1.627rem auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1.17rem;
    border-bottom: 1px solid #555;
  }

  .text-wrapper_20 {
    line-height: 0.64rem;
    /* padding-right: 2rem; */
    /* border-right: 1px solid #fff; */
  }

  .paragraph_25 {
    color: rgba(249, 247, 247, 1);
    font-size: 0.64rem;
    text-align: left;
    margin-bottom: 0.5rem;
  }

  .section_21 {
    margin: 1rem auto 0;
    width: 32rem;
    padding-bottom: 0.85rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .paragraph_26 {
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-wrap: break-word;
    color: rgba(249, 247, 247, 1);
    font-size: 0.426rem;
    text-align: left;
    line-height: 0.6rem;
  }

  .text_65 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  .text_66 {
    overflow-wrap: break-word;
    color: rgba(159, 159, 159, 1);
    font-size: 0.48rem;
    line-height: 0.48rem;
    margin: 0.32rem 0 0 0.027rem;
  }

  .text-group_12 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.426rem;
    text-align: center;
    white-space: nowrap;
    line-height: 0.587rem;
  }

  .box_54 {
    margin-left: 0.56rem;
  }

  .text_68 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.426rem;
    margin-bottom: 0.67rem;
  }

  .image-text_4 {
    width: 2.534rem;
  }

  .bottom-box::before {
    content: '';
    display: block;
    height: 1px;
    transform: scaleY(0.5);
    background: #fff;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .btn {
    background-color: #b701fb;
    color: #fff;
    font-size: 1.64rem;
  }
  .input {
    height: 3.62rem;
    font-size: 1.64rem;
    width: 16.45rem;
  }
  .textarea {
    margin: 0.1rem 0;
    width: 33.52rem;
    height: 5.05rem;
    font-size: 1.64rem;
  }
  .middle-box {
    width: 100%;
    order: 3;
  }
  .title {
    line-height: 1rem;
    height: 1rem;
  }
  .image_42 {
    width: 8.42rem;
    height: 8.42rem;
  }
  .text_78 {
    color: rgba(255, 255, 255, 1);
    font-size: 1.64rem;
    text-align: center;
  }

  .box {
    width: 76%;
  }
  .footer {
    background-color: #000;
  }
  .section_20 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text-wrapper_20 {
    border-bottom: 1px solid #333;
    padding: 3.49rem 1.1rem 3.01rem;
  }
  .paragraph_25 {
    display: block;
    color: rgba(249, 247, 247, 1);
    font-size: 2.05rem;
    margin-bottom: 1rem;
  }

  .section_21 {
    padding: 3.56rem 2.2rem 2.81rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .paragraph_26 {
    overflow-wrap: break-word;
    color: rgba(249, 247, 247, 1);
    font-size: 1.37rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .paragraph_26 > p {
    text-align: left;
    margin: 1.7rem 1.7rem 0 2.5rem;
    display: inline-block;
    overflow-wrap: break-word;
    color: rgba(249, 247, 247, 1);
    font-size: 1.37rem;
  }

  .text_65 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 2.88rem;
  }

  .text_66 {
    overflow-wrap: break-word;
    color: rgba(159, 159, 159, 1);
    font-size: 1.64rem;
    margin: 0.32rem 0 0 0.027rem;
  }

  .text-group_12 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.64rem;
    text-align: center;
    white-space: nowrap;
  }

  .box_54 {
    margin-left: 0.56rem;
  }

  .text_68 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.64rem;
    margin-bottom: 0.67rem;
  }

  .bottom-box::before {
    content: '';
    display: block;
    height: 1px;
    transform: scaleY(0.5);
    background: #555;
  }
}
</style>
