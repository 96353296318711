<template>
  <div class="flex-col">
    <imgHeader
      title="主营业务"
      msg="MAIN BUSINESS"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/8bb92126-7421-4491-81b0-3c29c9f68225矩形 1@2x.png"
      v-if="device === 'PC'"
    />
    <imgHeader
      title="主营业务"
      msg="MAIN BUSINESS"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/f6b0098b-3cde-4d91-8ca1-4d3570e49e2e矩形 1@2x (7).png"
      v-if="device === 'mobile'"
    />
    <div class="content">
      <div
        class="item-box flex-row justify-center"
        v-for="item in businessList"
        :key="item.title"
      >
        <div class="left-box">
          <div class="img-box">
            <img class="img" :src="item.blackImgUrl" alt="" />
          </div>
          <p class="title">{{ item.title }}</p>
        </div>
        <div class="right-box flex-col justify-center">
          <div class="msg-box">
            <p class="msg">{{ item.detail }}</p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>
<script>
import myFooter from "../components/my-footer.vue";
import imgHeader from "../components/img-header.vue";
import { getBusinessList } from "@/api/index.js";
export default {
  data() {
    return {
      businessList: [],
    };
  },
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
  created() {
    getBusinessList().then(({ data }) => {
      this.businessList = data.businessList;
    });
  },
  methods: {},
  components: {
    myFooter,
    imgHeader,
  },
};
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .content {
    padding: 3.52rem 9.6rem 2.59rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .title {
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 0.533rem;
    font-family: MicrosoftYaHei;
    white-space: nowrap;
    text-align: center;
    line-height: 0.534rem;
    margin-top: 1.2rem;
  }
  .msg {
    overflow-wrap: break-word;
    color: rgba(65, 65, 65, 1);
    font-size: 0.533rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 0.534rem;
  }
  .left-box {
    padding-right: 1.5rem;
    border-right: 1px solid #ddd;
    margin-right: 1rem;
  }
  .right-box {
    width: 8rem;
  }

  .item-box {
    box-shadow: 0px 8px 16px 4px rgba(7, 0, 2, 0.1);
    width: 15.73rem;
    height: 6.67rem;
    margin-bottom: 0.56rem;
    padding: 1.52rem 0 1.41rem 1.1rem;
  }
  .img-box {
    width: 1.52rem;
    height: 1.52rem;
    margin: 0.4rem auto 0;
  }
  .img {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .content {
    padding: 4.04rem 1.1rem 2.59rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .title {
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 2.05rem;
    font-family: MicrosoftYaHei;
    white-space: nowrap;
    text-align: center;
    line-height: 0.534rem;
    margin-top: 3.08rem;
    width: 13rem;
  }
  .msg {
    overflow-wrap: break-word;
    color: rgba(65, 65, 65, 1);
    font-size: 1.64rem;
    font-family: MicrosoftYaHei;
    text-align: left;
  }
  .left-box {
    padding-right: 3.77rem;
    border-right: 1px solid #ddd;
    margin-right: 2.33rem;
  }
  .right-box {
    width: 18.29rem;
  }

  .item-box {
    /* box-shadow: 0px 4px 8px 4px rgba(7, 0, 2, 0.05); */
    box-shadow: 0rem 1rem 1rem 0rem rgba(7, 0, 2, 0.1);
    height: 20.84rem;
    margin-bottom: 0.56rem;
    padding: 4.79rem 0 4.52rem 2.4rem;
    justify-content: left;
  }
  .img-box {
    width: 6.37rem;
    height: 6.37rem;
    margin: 0.4rem auto 0;
  }
  .img {
    width: 100%;
  }
}
</style>
