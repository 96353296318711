import request from '../utils/request.js'

//获取轮播图
export function getSwiperList(){
  return request({
    url:'/client/banner/getBannerList',
    method:'get'
  })
}

//获取主营业务
export function getBusinessList(){
  return request({
    url:'/client/business/getBusinessList',
    method:'get'
  })
}

//获取新闻类型列表
export function getNewsTypeList(){
  return request({
    url:'/client/news/getNewsType',
    method:'get'
  })
}

//根据类型 type 字段获取新闻列表
export function getNewsListByType(newsId,pageNum){
  return request({
    url:'/client/news/findNewsByNewsType?NewsType='+newsId+'&pageNum='+pageNum+'&pageSize=5'
  })
}

//获取案例类型列表
export function getCaseTypeList(){
  return request({
    url:'/client/case/getCaseType',
    method:'get'
  })
}

//根据类型type字段获取案例
export function getCaseListByType(anhuiCaseId,pageNum){
  return request({
    url:'/client/case/findCaseByCaseType?anhuiCaseType='+anhuiCaseId+'&pageNum='+pageNum+'&pageSize=10'
  })
}

//用户留言
export function addMessage(data){
  return request({
    url:'/client/leavingMessage/addMessage',
    method:'post',
    data:data
  })
}

//获取策划项目列表
export function getProjectList(){
  return request({
    url:'/client/project/findProjectList'
  })
}


//案例详情
export function getCaseDetails(caseId){
  return request({
    url:'/client/case/findCaseByCases/'+caseId
  })
}

//新闻详情
export function getNewsDetails(newsId){
  return request({
    url:'/client/news/findNewsByNewsId/'+newsId
  })
}