<template>
  <div class="youshi flex-col">
    <item-title title="我们的优势" msg="OUR ADVANTAGE"></item-title>
    <div class="block_19">
      <div class="item-box flex-col" v-for="(item, index) in list" :key="index">
        <div class="img-box">
          <img class="image_34" referrerpolicy="no-referrer" :src="item.url" />
        </div>
        <div class="text-group_19 flex-col justify-between">
          <span class="text_61">{{ item.title }}</span>
          <span class="paragraph_20">
            {{ item.msg }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemTitle from "./item-title.vue";
export default {
  name: "youshi",
  data() {
    return {
      list: [
        {
          title: "资源丰富",
          msg:
            "我们拥有9000家新闻媒体(综合门户媒体、行业垂直媒体、地方门户媒体),6000多家论坛,3000多家报纸,专业写手团队",
          url: "https://s1.ax1x.com/2022/11/04/xqgqsA.png",
        },
        {
          title: "专业团队",
          msg: "拥有十年互联网营销推广从业经验， 因为专注，所以专业",
          url: "https://s1.ax1x.com/2022/11/04/xqgTRe.png",
        },
        {
          title: "专员服务",
          msg: "我们提供专业营销顾问一对一服务， 可根据客户需求量身定制方案",
          url: "https://s1.ax1x.com/2022/11/04/xqg7xH.png",
        },
        {
          title: "一手渠道",
          msg: "全网一手渠道资源服务供应商， 不需要经过中间商，直接对接媒体合作",
          url: "https://s1.ax1x.com/2022/11/04/xqgoGD.png",
        },
        {
          title: "提供策划",
          msg: "我们的专业团队，根据您的推广需求 提供高效的整合营销方案",
          url: "https://s1.ax1x.com/2022/11/04/xqgbMd.png",
        },
        {
          title: "交易保证",
          msg: "正规公司可提供合同、发票 对公交易 有保障",
          url: "https://s1.ax1x.com/2022/11/04/xqgqsA.png",
        },
      ],
    };
  },
  components: {
    itemTitle,
  },
};
</script>
<style scoped>
.img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 0.05rem solid #fff;
  margin: 1.52rem auto 0;
}
.image_34 {
  width: 1.87rem;
  height: 1.87rem;
  margin: 0.8rem;
}

.youshi {
  padding: 3.41rem 0 3rem;
  /* background-color: #444; */
  background-color: #000;
}

.block_19 {
  width: 32rem;
  /* background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/ps7go37bnss98dpipoksf8lnj7p2cn7ia5j219d3da9-9788-4841-9529-9eaa51b9a69c) -0.48rem
    0rem no-repeat; */
  background-size: 56.934rem 15.76rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.text-group_19 {
  width: 9.067rem;
}

.text_61 {
  overflow-wrap: break-word;
  font-size: 0.8rem;
  text-align: center;
  white-space: nowrap;
  line-height: 0.8rem;
  margin: 0.5rem 0 0.32rem;
}

.paragraph_20 {
  font-size: 0.426rem;
  text-align: center;
  line-height: 0.63rem;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .youshi {
    background-color: #000;
  }
  .block_19 {
    width: 100%;
    padding: 0 1.1rem;
  }
  .item-box {
    width: 50%;
    align-items: center;
    margin-top: 4.6rem;
  }
  .img-box {
    width: 9.43rem;
    height: 9.43rem;
    border-radius: 50%;
    border: 0.05rem solid #fff;
    margin: 0 auto;
  }
  .image_34 {
    width: 4rem;
    height: 4rem;
    margin: 2.6rem;
  }

  .text_61 {
    overflow-wrap: break-word;
    font-size: 2.05rem;
    text-align: center;
    white-space: nowrap;
    line-height: 2.05rem;
    margin: 1.44rem auto 2.21rem;
  }
  .text-group_19 {
    width: 22.33rem;
  }
  .paragraph_20 {
    font-size: 1.37rem;
    text-align: center;
    line-height: 2.2rem;
  }
}
</style>
