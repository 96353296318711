<template>
  <div class="page">
    <imgHeader
      title="案例展示"
      msg="CASE PRESENTATION"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/2f8b5dda-af30-4016-9b84-1cd28434d41e矩形 1@2x (2).png"
      v-if="device === 'PC'"
    />
    <imgHeader
      title="案例展示"
      msg="CASE PRESENTATION"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/53b38ca9-575d-4053-a2e3-be3cdfd7e5561.png"
      v-else
    />
    <div class="content">
      <div class="img-box">
        <img class="img" :src="details.anhuiCaseCover" :alt="details.keywords" />
      </div>
      <div class="title">{{ details.anhuiCaseTitle }}</div>
      <div class="msg ql-editor " v-html="details.anhuiCaseContent"></div>
      <!-- <div class="msg">{{ details.anhuiCaseContent }}</div> -->
    </div>
    <myFooter />
  </div>
</template>
<script>
import imgHeader from "../../components/img-header.vue";
import myFooter from "../../components/my-footer.vue";
import { getCaseDetails } from "@/api/index.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  data() {
    return {
      details: {},
    };
  },
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
  components: {
    imgHeader,
    myFooter,
  },
  beforeCreate() {
    getCaseDetails(this.$route.query.anhuiCaseId).then((res) => {
      this.details = res.data.caseList[0];
      console.log("res.data.caseList[0];", res.data.caseList[0]);
    });
  },
};
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 480px) {
  .content {
    padding: 3.29rem 1.1rem 9.66rem;
  }
  .img-box {
    width: 100%;
  }
  .img {
    width: 100%;
  }
  .msg >>> img{
    width:100%;
  }
  .title {
    font-size: 2.47rem;
    margin: 1.44rem 0 1.07rem;
  }
  .msg {
    font-size: 1.64rem;
    color: #828282;
  }
}
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .content {
    padding: 1.71rem 9.6rem 2.69rem;
  }
  .img-box {
    width: 100%;
  }
  .img {
    display: block;
    width: 30%;
    margin: 0 auto;
  }
  .msg >>> img{
    width:50%;
  }
  .title {
    font-size: 0.91rem;
    margin: 1.44rem 0 1.07rem;
  }
  .msg {
    font-size: 0.64rem;
    color: #828282;
  }
}
</style>
