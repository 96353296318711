<template>
  <div class="xinwen flex-col">
    <item-title title="新闻资讯" msg="NEWS INFORMATION"></item-title>
    <div class="btn-box">
      <switch-btn
        class="switch-btn-component"
        @switch-click="switchClick(item.newsTypeId)"
        v-for="item in newsTypeList"
        :key="item.newTypeName"
        :title="item.newTypeName"
      ></switch-btn>
      <switch-btn
        class="more switch-btn-component"
        title="MORE >"
        @switch-click="routeToNewsTab"
      ></switch-btn>
    </div>
    <div class="big-box flex-row">
      <div
        class="box_39 flex-col justify-between"
        @click="navigateToDetails(news_1.newsId)"
      >
        <div class="section_1 flex-col img-box">
          <img class="news-img" :src="news_1.newsCover" alt="" />
        </div>
        <div class="section_1 flex-col msg-box-big justify-between">
          <div class="flex-col">
            <div class="date-box flex-col justify-between">
              <div class="year">{{ news_1.createTime.split('-')[0] }}</div>
              <div class="date">
                {{
                  news_1.createTime.split('-')[1] +
                  '/' +
                  news_1.createTime.split('-')[2]
                }}
              </div>
            </div>
            <div class="msg-box flex-col justify-between">
              <div class="title2">{{ news_5.newsTitle }}</div>
              <div
                class="msg-box5 justify-center"
                v-html="news_5.newsContent"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="box_39 flex-col justify-between"
        @click="navigateToDetails(news_2.newsId)"
      >
        <div class="section_1 flex-col msg-box-big justify-between">
          <div class="flex-col">
            <div class="date-box flex-col justify-between">
              <div class="year">{{ news_2.createTime.split('-')[0] }}</div>
              <div class="date">
                {{
                  news_2.createTime.split('-')[1] +
                  '/' +
                  news_2.createTime.split('-')[2]
                }}
              </div>
            </div>
            <div class="msg-box flex-col justify-between">
              <div class="title2">{{ news_5.newsTitle }}</div>
              <div
                class="msg-box5 justify-center"
                v-html="news_5.newsContent"
              ></div>
            </div>
          </div>
        </div>
        <div class="section_1 flex-col img-box">
          <img class="news-img" :src="news_2.newsCover" alt="" />
        </div>
      </div>
      <div class="box_41 flex-col justify-between">
        <div
          class="section3 flex-row"
          @click="navigateToDetails(news_3.newsId)"
        >
          <div class="right-box flex-row">
            <div class="date-box2 flex-col justify-center">
              <div class="year2">{{ news_3.createTime.split('-')[0] }}</div>
              <div class="date2">
                {{
                  news_3.createTime.split('-')[1] +
                  '/' +
                  news_3.createTime.split('-')[2]
                }}
              </div>
            </div>
            <div class="msg-box3 flex-col justify-between">
              <div class="title2">{{ news_3.newsTitle }}</div>
              <div
                class="msg-box4 justify-center"
                v-html="news_3.newsContent"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="section3 flex-row"
          @click="navigateToDetails(news_4.newsId)"
        >
          <div class="right-box flex-row">
            <div class="date-box2 flex-col justify-center">
              <div class="year2">{{ news_4.createTime.split('-')[0] }}</div>
              <div class="date2">
                {{
                  news_4.createTime.split('-')[1] +
                  '/' +
                  news_4.createTime.split('-')[2]
                }}
              </div>
            </div>
            <div class="msg-box3 flex-col justify-between">
              <div class="title2">{{ news_4.newsTitle }}</div>
              <div
                class="msg-box4 justify-center"
                v-html="news_4.newsContent"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="section3 flex-row"
          @click="navigateToDetails(news_5.newsId)"
        >
          <div class="right-box flex-row">
            <div class="date-box2 flex-col justify-center">
              <div class="year2">{{ news_5.createTime.split('-')[0] }}</div>
              <div class="date2">
                {{
                  news_5.createTime.split('-')[1] +
                  '/' +
                  news_5.createTime.split('-')[2]
                }}
              </div>
            </div>
            <div class="msg-box3 flex-col justify-between">
              <div class="title2">{{ news_5.newsTitle }}</div>
              <div
                class="msg-box4 justify-center"
                v-html="news_5.newsContent"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="big-box-m">
      <div
        class="item flex-row justify-between"
        v-for="item in list"
        :key="item.newsId"
        @click="navigateToDetails(item.newsId)"
      >
        <div class="left-box">
          <img class="news-img" :src="item.newsCover" alt="" />
        </div>
        <div class="right-box">
          <div class="year">{{ item.createTime.split('-')[0] }}</div>
          <div class="date">
            {{
              item.createTime.split('-')[1] +
              '/' +
              item.createTime.split('-')[2]
            }}
          </div>
          <div class="title">{{ item.newsTitle }}</div>
          <div class="msg" v-html="item.newsContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemTitle from './item-title.vue'
import switchBtn from '../../components/switch-btn.vue'
import { getNewsTypeList, getNewsListByType } from '@/api/index.js'
export default {
  name: 'news-information',
  data() {
    return {
      newsTypeList: [],
      list: [],
      news_1: {
        createTime: '',
      },
      news_2: {
        createTime: '',
      },
      news_3: {
        createTime: '',
      },
      news_4: {
        createTime: '',
      },
      news_5: {
        createTime: '',
      },
    }
  },
  methods: {
    navigateToDetails(newsId) {
      this.$router.push('/newsDetails?newsId=' + newsId)
    },
    switchClick(id) {
      getNewsListByType(id).then(({ data }) => {
        this.news_1 = data.newsList[0]
        this.news_2 = data.newsList[1]
        this.news_3 = data.newsList[2]
        this.news_4 = data.newsList[3]
        this.news_5 = data.newsList[4]
        this.list = data.newsList.slice(0, 3)
      })
    },
    routeToNewsTab() {
      console.log('this.$router', this.$router)
      this.$router.push('/news')
    },
  },
  components: {
    itemTitle,
    switchBtn,
  },
  created() {
    getNewsTypeList().then(({ data }) => {
      this.newsTypeList = data.newsTypeList
    })
    this.switchClick(1)
  },
}
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  @keyframes big {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.03);
    }
  }
  .section_1:hover,
  .section3:hover {
    cursor: pointer;
    animation-name: big;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  .title2 {
    font-size: 0.48rem;
    line-height: 0.8rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* 可以显示的行数，超出部分用...表示*/
    -webkit-box-orient: vertical;
  }
  .msg-box5 {
    text-indent: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* 可以显示的行数，超出部分用...表示*/
    -webkit-box-orient: vertical;
  }
  .msg-box4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* 可以显示的行数，超出部分用...表示*/
    -webkit-box-orient: vertical;
  }
  .big-box-m {
    display: none;
  }
  .msg-box-big {
    padding: 0.4rem;
  }
  .news-img {
    width: 100%;
  }
  .switch-btn-component {
    color: #fff;
  }
  .more {
    float: right;
    margin-top: 0.2rem;
  }
  .btn-box {
    width: 32rem;
    margin: 1.36rem auto 0rem;
  }
  .year {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 0.68rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 1.014rem;
  }
  .date {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 1.14rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 1.014rem;
  }
  .year2 {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 0.58rem;
    font-family: MicrosoftYaHei;
    text-align: center;
    line-height: 1.014rem;
  }
  .date2 {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 0.74rem;
    font-family: MicrosoftYaHei;
    text-align: center;
    white-space: nowrap;
    line-height: 1.014rem;
  }
  .date-box {
    /* width: 5.5rem; */
    padding-bottom: 0.2rem;
    border-bottom: 1px solid #ccc;
  }
  .date-box2 {
    /* width: 5.5rem; */
    padding: 0 0.5rem;
    border-right: 1px solid #ccc;
  }
  .msg-box {
    padding-top: 0.4rem;
    overflow-wrap: break-word;
    color: rgba(95, 95, 95, 1);
    font-size: 0.325rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 0.48rem;
  }

  .msg-box3 {
    width: 9rem;
    overflow-wrap: break-word;
    color: rgba(95, 95, 95, 1);
    font-size: 0.325rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 0.48rem;
    padding: 0rem 0.4rem 0.4rem 0.4rem;
  }
  .xinwen {
    padding: 3.04rem 0 2rem;
    background-color: #000;
  }

  .big-box {
    height: 11.627rem;
    margin: 0.51rem auto 0;
  }

  .box_39 {
    width: 9.28rem;
    margin-right: 0.25rem;
  }

  .section_1 {
    background-color: rgba(255, 255, 255, 1);
    height: 48.4%;
  }

  .box_41 {
    width: 12.907rem;
  }

  .section3 {
    background-color: rgba(255, 255, 255, 1);
    width: 12.907rem;
    height: 32%;
    padding: 0.4rem;
    overflow: hidden;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .left-box,
  .right-box {
    height: 14.54rem;
    width: 24.18rem;
    overflow: hidden;
  }
  .right-box {
    background-color: #fff;
    padding: 1.23rem 1.03rem 0 1.58rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .big-box-m {
    display: flex;
    flex-direction: column;
  }

  .xinwen {
    padding: 7.19rem 1.1rem 2rem;
    background-color: #000;
  }
  .msg-box-big {
    padding: 0.4rem;
  }
  .news-img {
    width: 100%;
  }
  .switch-btn-component {
    color: #fff;
  }
  .more {
    float: right;
    margin-top: 0.2rem;
  }
  .btn-box {
    margin: 2.81rem 0 1.71rem;
  }
  .title {
    font-size: 1.64rem;
    margin-top: 1rem;
  }
  .msg {
    font-size: 1.03rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5a5a5a;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 可以显示的行数，超出部分用...表示*/
    -webkit-box-orient: vertical;
  }
  .year {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 1.23rem;
    font-family: MicrosoftYaHei;
    text-align: left;
  }
  .date {
    overflow-wrap: break-word;
    color: rgba(112, 112, 112, 1);
    font-size: 2.47rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid #a199a4;
    line-height: 2rem;
    padding-bottom: 1rem;
  }

  .big-box {
    /* width: 31.947rem; */
    display: none;
  }
  .item {
    margin-top: 0.82rem;
  }
}
</style>
