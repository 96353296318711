<template>
  <div class="swiper">
    <swiper ref="mySwiper" :options="swiperOptions" class="image-container">
      <swiper-slide v-for="(item, index) in swiperList" :key="index">
        <img class="img-item" :src="item.imgUrl"  v-show="device === 'PC'" />
        <img
          class="img-item2"
          :src="item.imgUrlShowPhone"
          v-show="device === 'mobile'"
        />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="swiper-text">
      <div class="title"><i>中国领先的传媒运营集团</i></div>
      <div class="msg">
        丰富的品牌、广告、公安、策划。营销经验，为企业提供全方位一站式网络营销解决方案
      </div>
      <div class="more-box">
        <div class="more flex-row justify-start">
          <router-link to="/aboutUs" class="tab1">MORE</router-link>
          <router-link to="/aboutUs" class="tab2">
            <i class="el-icon-arrow-right"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "my-swiper",
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: true, //可选选项，自动滑动
      },
    };
  },
  methods: {

  },
  props: {
    swiperList: Array,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    device() {
      return this.$store.state.userDevice;
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
  },
};
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 480px) {
  .image-container {
    height: 68.40rem;
    background-color: #000;
  }
  .img-item {
    width: 100%;
  }
  .img-item2{
    width: 100%;
  }
  .more {
    margin-top: 2rem;
    width: 4rem;
  }
  .tab1 {
    width: 4rem;
    color: white;
    font-size: 1.23rem;
    border-bottom: 3px solid #aa24fd;
    padding-bottom: 1rem;
  }
  .tab2 {
    width: 2rem;
    color: white;
    font-size: 0.48rem;
    border-bottom: 3px solid #fcb800;
  }
  .title {
    font-size: 4.11rem;
    font-weight: 200;
    width: 29rem;
  }
  .msg {
    margin: 4.59rem 0 5.07rem;
    width: 34rem;
    font-size: 1.64rem;
  }
  .swiper {
    position: relative;
    background-color: rgba(103, 91, 91, 0.9);
  }
  .swiper-text {
    color: white;
    position: absolute;
    padding: 23rem 0 0 1.1rem;
    top: 0rem;
    left: 0rem;
    z-index: 1000;
  }
}
@media not screen and (min-width: 320px) and (max-width: 480px) {

  .title {
    font-size: 1.92rem;
    font-weight: 200;

    font-style: italic;
  }
  .msg {
    margin: 1.17rem 0 2.24rem;
    width: 13rem;
    font-size: 0.64rem;
    line-height: 1.3rem;
  }
  .swiper {
    position: relative;
    background-color: rgba(103, 91, 91, 0.9);
  }
  .swiper-text {
    color: white;
    position: absolute;
    padding: 11.3rem 0 0 9.6rem;
    top: 0rem;
    left: 0rem;
    z-index: 1000;
  }
  .image-container {
    height: 27.09rem;
    margin: 0 auto;
  }
  .img-item {
    width: 100%;
  }
  .more {
    margin-top: 2rem;
    width: 4rem;
  }
  .tab1 {
    width: 2rem;
    color: white;
    font-size: 0.48rem;
    border-bottom: 3px solid #aa24fd;
    padding-bottom: 0.5rem;
  }
  .tab2 {
    width: 1rem;
    color: white;
    font-size: 0.48rem;
    border-bottom: 3px solid #fcb800;
    padding-bottom: 0.5rem;
  }
}
</style>
