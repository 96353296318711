<template>
  <div class="anli flex-col">
    <item-title title="案例展示" msg="CASE PRESENTATION"></item-title>
    <div class="btn-box flex-row justify-between">
      <switch-btn
        class="switch-btn-component"
        v-for="item in caseTypeList"
        :key="item.caseTypeName"
        :title="item.caseTypeName"
        @switch-click="getList(item.caseTypeId)"
      ></switch-btn>
    </div>
    <div class="content">
      <div class="content2">
        <div class="item-container flex-row justify-between">
          <div class="list1-group">
            <div
              class="item"
              v-for="item in list1"
              :key="item.anhuiCaseId"
              ref="items"
              @click="navigateToDetails(item.anhuiCaseId)"
            >
              <img class="img-item" :src="item.anhuiCaseCover" :alt="item.keywords" />
            </div>
          </div>
          <div class="list2-group">
            <div
              class="item"
              v-for="item in list2"
              :key="item.anhuiCaseId"
              ref="items2"
              @click="navigateToDetails(item.anhuiCaseId)"
            >
              <img class="img-item" :src="item.anhuiCaseCover" :alt="item.keywords" />
            </div>
          </div>
          <div class="list3-group" v-if="device === 'PC'">
            <div
              class="item"
              v-for="item in list3"
              :key="item.anhuiCaseId"
              ref="items3"
              @click="navigateToDetails(item.anhuiCaseId)"
            >
              <img class="img-item" :src="item.anhuiCaseCover" :alt="item.keywords" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemTitle from "./item-title.vue";
import switchBtn from "../../components/switch-btn.vue";
import { getCaseTypeList, getCaseListByType } from "@/api/index.js";
export default {
  name: "",
  data() {
    return {
      caseTypeList: [],
      caseList: [],
      bigImg: [],
      middleImg: [],
      smallImg: [],
      list1: [],
      list2: [],
      list3: [],
      height_1: 0,
      height_2: 0,
      height_3: 0,
    };
  },
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
  methods: {
    navigateToDetails(caseId) {
      this.$router.push("/caseDetails?anhuiCaseId=" + caseId);
    },
    getList(id) {
      getCaseListByType(id).then(({ data }) => {
        this.list1 = [];
        this.list2 = [];
        this.list3 = [];
        this.height_1=0;
        this.height_2=0;
        this.height_3=0;
        if (this.device === "PC") {
          for (let i = 0; i < data.caseList.length; i++) {
            if (this.height_1 <= this.height_2 && this.height_1 <= this.height_3) {
              this.list1.push(data.caseList[i]);
              this.height_1 += data.caseList[i].coverHeight;
            } else if (this.height_2 <= this.height_1 && this.height_2 <= this.height_3) {
              this.list2.push(data.caseList[i]);
              this.height_2 += data.caseList[i].coverHeight;
            } else {
              this.list3.push(data.caseList[i]);
              this.height_3 += data.caseList[i].coverHeight;
            }
          }
        } else {
          for (let i = 0; i < data.caseList.length; i++) {
            if (this.height_1 <= this.height_2) {
              this.list1.push(data.caseList[i]);
              this.height_1 += data.caseList[i].coverHeight;
            } else {
              this.list2.push(data.caseList[i]);
              this.height_2 += data.caseList[i].coverHeight;
            }
          }
          console.log("this.height_1", this.height_1);
          console.log("this.height_2", this.height_2);
        }
      });
    },
    getTypeList() {
      let that = this;
      return getCaseTypeList().then(({ data }) => {
        that.caseTypeList = data.caseTypeList;
      });
    },
  },
  created() {
    this.getTypeList();
    this.getList(1);
  },
  components: {
    itemTitle,
    switchBtn,
  },
};
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .list1-group,
  .list2-group,
  .list3-group {
    width: 32%;
  }
  @keyframes big {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.03);
    }
  }

  .item:hover {
    animation-name: big;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  .img-item {
    width: 100%;
  }

  .content {
    padding: 0rem 8rem 0rem;
    background-color: #000;
  }
  .content2 {
    padding: 0 1.6rem 0rem;
  }
  .switch-btn-component {
    color: #fff;
  }
  .btn-box {
    width: 32rem;
    margin: 1.36rem auto 0.67rem;
  }
  .anli {
    padding-top: 4rem;
    /* background-color: #555; */
    background-color: #000;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .list1-group,
  .list2-group,
  .list3-group {
    width: 49.5%;
  }

  .content {
    padding: 3.29rem 1.1rem 0;
  }
  .img-item {
    width: 100%;
  }

  .switch-btn-component {
    color: #fff;
  }

  .anli {
    padding: 4rem 1.1rem 0;
    background-color: #000;
  }

  .btn-box {
    width: 100%;
    margin: 3.7rem 0 1.85rem;
  }
}
</style>
