<template>
  <div class="container">
    <div class="box flex-row justify-between">
      <div class="logo-box">
        <img
          class="logo"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/psr9inxbyi4f9dlvz32i5agcfur70vjh20i86ff4be0-105a-4d41-9244-6c388ceb7c03"
        />
      </div>
      <div class="bar-box">
        <div class="bar-img-box">
          <img
            class="bar"
            src="https://s1.ax1x.com/2022/11/16/zZle7d.png"
            v-if="barShow"
            @click="handleBarClick"
            alt=""
          />
          <img
            class="bar"
            src="https://s1.ax1x.com/2022/11/16/zZJKQx.png"
            v-if="backShow"
            @click="handleBackClick"
            alt=""
          />
        </div>
      </div>
      <div class="tabbar-box flex-row">
        <router-link to="/" class="tab">首页</router-link>
        <div class="xie">/</div>
        <router-link to="/aboutUs" class="tab">关于我们</router-link>
        <div class="xie">/</div>
        <router-link to="/zhuyingyewu" class="tab">主营业务</router-link>
        <div class="xie">/</div>
        <router-link to="/planProject" class="tab">策划项目</router-link>
        <div class="xie">/</div>
        <router-link to="/advantage" class="tab">我的优势</router-link>
        <div class="xie">/</div>
        <router-link to="/caseShow" class="tab">案例展示</router-link>
        <div class="xie">/</div>
        <router-link to="/news" class="tab">新闻资讯</router-link>
        <div class="xie">/</div>
        <router-link to="/lianxiwomen" class="tab">联系我们</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "my-header",
  methods: {
    handleBarClick() {
      this.$router.push("/myBar");
    },
    handleBackClick() {
      this.$router.go(-1);
    },
  },
  computed: {
    backShow: function () {
      return this.$route.name === "myBar";
    },
    barShow: function () {
      return this.$route.name === "myBar" ? false : true;
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 480px) {
  .tabbar-box {
    display: none;
  }
  .bar {
    width: 100%;
  }
  .bar-img-box {
    width: 3.08rem;
  }
  .bar-box {
    margin-right: 1.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo-box {
    height: 8.56rem;
    width: 14.43rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1.1rem;
    align-items: center;
  }
  .logo {
    /* width: 5.57rem;
    height: 1.55rem; */
    width: 100%;
  }
  .box {
    width: 100%;
    height: 8.56rem;
    position: fixed;
    top: 0;
    left: 0;
    /* height: 8.06rem; */
    /* border-bottom:1px solid #ddd; */
    background: rgba(33, 33, 33, 0.7);
  }
  .xie {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.46rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 0.46rem;
    /* margin: 0.587rem 0 0 .494rem; */
    margin: 1.31rem 0;
  }
  .container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 100vw;
  }
  .container::after {
    content: "";
    display: none;
    height: 1px;
    transform: scaleY(0.5);
    /**/
    background: #fff;
  }
  .tab {
    color: rgba(255, 255, 255, 1);
    font-size: 0.46rem;
    line-height: 0.46rem;
    padding: 1.23rem 0.36rem;
  }
  .tab:hover {
    border-bottom: 2px solid #b701fb;
    color: #b701fb;
    font-weight: 600;
  }
}

@media not screen and (min-width: 320px) and (max-width: 480px) {
  .tab.router-link-exact-active {
    color: #b701fb;
    border-bottom: 2px solid #b701fb;
  }
  .box {
    width: 32rem;
    margin: 0 auto;
  }
  .logo-box {
    margin: 0.48rem 0;
  }
  .logo {
    width: 5.57rem;
    height: 1.55rem;
    margin-top: 0.25rem;
  }
  .bar-box {
    display: none;
  }
  .xie {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.46rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 0.46rem;
    /* margin: 0.587rem 0 0 .494rem; */
    margin: 1.25rem 0;
  }
  .container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 100vw;
    background: rgba(255, 255, 255, 0);
  }
  .container::after {
    content: "";
    display: block;
    height: 1px;
    transform: scaleY(0.5);
    /**/
    background: #fff;
  }
  .tab {
    color: rgba(255, 255, 255, 1);
    font-size: 0.46rem;
    line-height: 0.46rem;
    padding: 1.23rem 0.36rem;
  }
  .tab:hover {
    border-bottom: 2px solid #b701fb;
    color: #b701fb;
    font-weight: 600;
  }
}
</style>
