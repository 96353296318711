<template>
  <div class="link-box">
    <div class="link-group">
      <div class="link" v-for="item in routeList" :key="item.route" @click="handleLinkClick(item.route)">
        <div class="title">{{item.label}}</div>
        <div class="right-icon"><img src="https://s1.ax1x.com/2022/11/18/znkVaV.png" alt=""></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "my-bar",
  data() {
    return {
      routeList: [
        {
          label: "首页",
          route: "/",
        },
        {
          label: "关于我们",
          route: "/aboutUs",
        },
        {
          label: "主营业务",
          route: "/zhuyingyewu",
        },
        {
          label: "策划项目",
          route: "/planProject",
        },
        {
          label: "我的优势",
          route: "/advantage",
        },
        {
          label: "案例展示",
          route: "/caseShow",
        },
        {
          label: "新闻资讯",
          route: "/news",
        },
        {
          label: "联系我们",
          route: "/lianxiwomen",
        },
      ],
    };
  },
  methods: {
    handleLinkClick(link){
      this.$router.push(link)
    }
  },
};
</script>
<style scoped>
.link-box{
  height: 100vh;
}
.right-icon{
  float: right;
}
.title{
  float:left;
}
.link-box{
  background-color: #2E2E2E;
  padding: 70px 8px 0px;
}
.link {
  font-size: 15px;
  color: #ddd;
  line-height: 68px;
  height: 68px;
  border-bottom: 0.5px solid #aaa;
}
/* .link::after {
    content: "";
    display: block;
    height: 1px;
    transform: scaleY(0.5);
    background: #fff;
  } */
</style>
