<template>
  <div class="center flex-row">
    <div class="border-box"></div>
    <div class="text-group_13 flex-col justify-between">
      <span class="text_33">{{ title }}</span>
      <span class="text_34">{{ msg }}</span>
    </div>
    <div class="border-box"></div>
  </div>
</template>
<script>
export default {
  name: "item-title",
  props: {
    title: String,
    msg: String,
  },
};
</script>
<style scoped>
.border-box {
  background-color: #fff;
  width: 2rem;
  height: 1px;
  margin: 1.1rem 1rem 0;
}

.center {
  margin: 0 auto;
}

.text-group_13 {
  margin: 0 0.96rem;
}

.text_33 {
  margin: 0 auto;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.28rem;
  font-family: MicrosoftYaHei;
  text-align: left;
  white-space: nowrap;
  line-height: 1.28rem;
}

.text_34 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.8rem;
  font-family: MicrosoftYaHei;
  text-align: left;
  white-space: nowrap;
  line-height: 0.8rem;
  margin: 0.507rem auto 0;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .border-box {
    background-color: #fff;
    width: 3.96rem;
    height: 1px;
    margin: 1.1rem 1rem 0;
  }

  .center {
    margin: 0 auto;
  }

  .text-group_13 {
    margin: 0 0.96rem;
  }

  .text_33 {
    margin: 0 auto;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 2.47rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
  }

  .text_34 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 11.27px;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 0.8rem;
    margin: 14px auto 0;
  }
}
</style>
