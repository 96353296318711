<template>
  <div class="page">
    <imgHeader
      title="我的优势"
      msg="OUR ADVANTAGE"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/f612eadb-64c2-4bc7-aeb5-f053f920b039矩形 1@2x (5).png"
      v-if="device === 'PC'"
    />
    <imgHeader
      title="我的优势"
      msg="OUR ADVANTAGE"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/38ffbb7c-e3a1-4c61-bd4b-01f6aa7997fa矩形 1@2x (8).png"
      v-if="device === 'mobile'"
    />
    <div class="block_19">
      <div class="item-box flex-col" v-for="item in list" :key="item.url">
        <div class="img-box">
          <img class="image_34" referrerpolicy="no-referrer" :src="item.url" />
        </div>
        <div class="text-group_19 flex-col justify-between">
          <span class="text_61">{{ item.title }}</span>
          <span class="paragraph_20">
            {{ item.msg }}
          </span>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>
<script>
import myFooter from "../components/my-footer.vue";
import imgHeader from "../components/img-header.vue";
export default {
  data() {
    return {
      list: [
        {
          title: "资源丰富",
          msg:
            "我们拥有9000家新闻媒体(综合门户媒体、行业垂直媒体、地方门户媒体),6000多家论坛,3000多家报纸,专业写手团队",
          url: "https://s1.ax1x.com/2022/11/07/xv89iR.png",
        },
        {
          title: "专业团队",
          msg: "拥有十年互联网营销推广从业经验， 因为专注，所以专业",
          url: "https://s1.ax1x.com/2022/11/07/xv8So9.png",
        },
        {
          title: "专员服务",
          msg: "我们提供专业营销顾问一对一服务， 可根据客户需求量身定制方案",
          url: "https://s1.ax1x.com/2022/11/07/xv3zdJ.png",
        },
        {
          title: "一手渠道",
          msg: "全网一手渠道资源服务供应商， 不需要经过中间商，直接对接媒体合作",
          url: "https://s1.ax1x.com/2022/11/07/xv3xZ4.png",
        },
        {
          title: "提供策划",
          msg: "我们的专业团队，根据您的推广需求 提供高效的整合营销方案",
          url: "https://s1.ax1x.com/2022/11/07/xv3jLF.png",
        },
        {
          title: "交易保证",
          msg: "正规公司可提供合同、发票 对公交易 有保障",
          url: "https://s1.ax1x.com/2022/11/07/xv8CJ1.png",
        },
      ],
    };
  },
  methods: {},
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
  components: {
    myFooter,
    imgHeader,
  },
};
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .img-box {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    border: 0.08rem solid #000;
    margin: 1.52rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image_34 {
    width: 1.65rem;
    height: 1.65rem;
  }

  .block_19 {
    padding: 3.33rem 9.6rem 8.1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .text-group_19 {
    width: 9.067rem;
  }

  .text_61 {
    overflow-wrap: break-word;
    font-size: 0.8rem;
    text-align: center;
    white-space: nowrap;
    line-height: 0.8rem;
    margin: 0.5rem 0 0.32rem;
  }

  .paragraph_20 {
    font-size: 0.426rem;
    text-align: center;
    line-height: 0.63rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .item-box {
    width: 50%;
    margin-bottom: 4.66rem;
  }
  .img-box {
    width: 9.43rem;
    height: 9.43rem;
    border-radius: 50%;
    border: 0.08rem solid #000;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image_34 {
    width: 3.7rem;
    height: 3.7rem;
  }

  .block_19 {
    padding: 7.81rem 1.1rem 8.1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .text_61 {
    overflow-wrap: break-word;
    font-size: 2.05rem;
    text-align: center;
    white-space: nowrap;
    margin: 1.44rem 0 2.12rem 0.32rem;
  }

  .paragraph_20 {
    font-size: 1.37rem;
    text-align: center;
  }
}
</style>
