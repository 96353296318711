<template>
  <div class="page flex-col">
    <imgHeader
      title="联系我们"
      msg="CONTACT US"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/57524716-f010-4c97-b972-926ebbabd4c9矩形 1@2x (4).png"
      v-if="device === 'PC'"
    />
    <imgHeader
      title="联系我们"
      msg="CONTACT US"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/753f3e45-983d-4950-992d-d53e4afa35a4矩形 1@2x (6).png"
      v-else
    />
    <div class="content">
      <div class="box">
        <div class="item">
          <div class="logo-box">
            <img class="img1" src="https://s1.ax1x.com/2022/11/08/xxscCV.png" alt="" />
          </div>
          <div class="msg-box">
            <div class="title">业务联系</div>
            <div class="msg">
              <div>业务热线: 400-688-6062</div>
              <div>业务QQ: 455605838</div>
              <div>业务邮箱: sales@szweb.cn</div>
              <div>大客户专线: 15818561755</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="logo-box">
            <img class="img2" src="https://s1.ax1x.com/2022/11/08/xxsg3T.png" alt="" />
          </div>
          <div class="msg-box">
            <div class="title">售后服务</div>
            <div class="msg">
              <div>服务热线: 020-80929074</div>
              <div>售后邮箱: kf@szweb.cn</div>
              <div>客服QQ: 1669528584</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="logo-box">
            <img class="img3" src="https://s1.ax1x.com/2022/11/08/xxsy40.png" alt="" />
          </div>
          <div class="msg-box">
            <div class="title">运营渠道</div>
            <div class="msg">
              <div>邮箱: miaochun@szweb.cn</div>
              <div>QQ: 2693564150</div>
              <div>投诉邮箱: tousu@szweb.cn</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="way">
      <div class="left-box flex-col">
        <div class="big-title">如何到达安惠!</div>
        <div class="eng-title">HOW TO GET TO ANHUI</div>
        <div class="address"><b>地址</b> : 深圳市福田区车公庙创新科技广场一期A座11层</div>
        <div class="img-box2">
          <img class="img-QR" src="https://s1.ax1x.com/2022/11/08/xxssNq.png" alt="" />
        </div>
      </div>
      <div class="right-box flex-col justify-between">
        <div>
          <div class="car-title">途径车辆：</div>
          <div class="car-msg">
            228路234路301路311路320路324路324区间326路328路329路32路338路K318路M372路(原350路)M391路M392路M413路M414路N4路N5路高峰15号专线高峰49号专线高峰专线18号高快巴士13号(GK13)观光巴士线海上田园旅游专线2路机场9线
          </div>
        </div>
        <div>
          <div class="underground">
            <div class="underground-title">附近地铁站：</div>
            <div class="underground-msg">罗宝线车公庙J出口</div>
          </div>
        </div>
      </div>
    </div>
    <div class="advise">
      <div class="advise-title">请您将问题或建议告诉我们，安惠竭诚为您服务!</div>
      <div class="input-group flex-row justify-between">
        <div class="left-group">
          <van-field
            class="input"
            label-class="label"
            v-model="username"
            label="您的姓名"
            placeholder="请输入姓名"
          />
          <van-field
            class="input"
            label-class="label"
            v-model="userPhone"
            label="您的电话"
            placeholder="请输入电话"
          />
          <van-field
            class="input"
            label-class="label"
            v-model="userBusiness"
            label="公司名称"
            placeholder="请输入公司名称"
          />
          <van-field
            class="input"
            label-class="label"
            v-model="userEmail"
            label="E-mail"
            placeholder="请输入E-mail"
          />
          <!-- <van-field
            class="input"
            label-class="label"
            v-model="keys"
            label="验证码"
            placeholder="请输入验证码"
          /> -->
        </div>
        <div class="right-group">
          <van-field
            v-model="message"
            class="label textarea"
            label-width="2em"
            rows="8"
            autosize
            label="留言"
            type="textarea"
            placeholder="请输入留言"
          />
        </div>
      </div>
      <div class="submit-btn flex-row justify-center">
        <el-button class="submit" @click="submitForm" :disabled="btnDisabled" size="small"
          >确认提交</el-button
        >
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>
    <myFooter />
  </div>
</template>
<script>
import myFooter from "../components/my-footer.vue";
import imgHeader from "../components/img-header.vue";
import { addMessage } from "@/api/index.js";
export default {
  data() {
    return {
      btnDisabled: false,
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      username: "",
      userPhone: "",
      userBusiness: "",
      userEmail: "",
      message: "",
    };
  },
  computed: {
    form: function () {
      return {
        nickName: this.username,
        phone: this.userPhone,
        corporateName: this.userBusiness,
        email: this.userEmail,
        message: this.message,
      };
    },
    device() {
      return this.$store.state.userDevice;
    },
  },
  methods: {
    reset() {
      this.username = "";
      this.userPhone = "";
      this.userBusiness = "";
      this.userEmail = "";
      this.message = "";
    },
    submitForm() {
      if (
        this.username !== "" &&
        this.userPhone !== "" &&
        this.userBusiness !== "" &&
        this.userEmail !== "" &&
        this.message !== ""
      ) {
        addMessage(this.form).then(({ data }) => {
          if (data.msg === "留言成功") {
            this.$message({
              message: "留言成功",
              type: "success",
            });
          }
        });
        this.reset();
        this.btnDisabled = true;
        setTimeout(() => {
          this.btnDisabled = false;
        }, 3000);
      } else {
        if (this.username === "") {
          this.$message.error("请输入您的姓名");
        } else if (this.userPhone === "") {
          this.$message.error("请输入您的电话");
        } else if (this.userBusiness === "") {
          this.$message.error("请输入您的公司名称");
        } else if (this.userEmail === "") {
          this.$message.error("请输入您的邮箱地址");
        } else {
          this.$message.error("请输入您的留言");
        }
      }
    },
  },
  components: {
    myFooter,
    imgHeader,
  },
};
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 480px) {
  .submit {
    margin-right: 1rem;
    background-color: #b400ff;
    color: #fff;
  }
  .item {
    width: 20.5rem;
    margin: 0 auto 3.49rem;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  /* .box{

  } */
  .submit-btn {
    margin: 0.8rem auto;
  }
  .textarea {
    border: 1px solid #eee;
    box-shadow: 0px 2px 16px 2px rgb(7 0 2 / 5%);
  }
  .right-group {
    margin: 1rem 0;
  }
  .label {
    color: #999797;
  }
  .input {
    border-bottom: 1px solid #eee;
    margin-bottom: 0.33rem;
  }
  .advise {
    padding: 1.52rem 1.1rem;
  }
  .advise-title {
    overflow-wrap: break-word;
    color: rgba(60, 60, 60, 1);
    font-size: 2.05rem;
    font-family: MicrosoftYaHei;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 1.92rem;
  }
  .car-title,
  .underground-title {
    color: #333;
    font-size: 1.99rem;
  }
  .car-msg,
  .underground-msg {
    margin-top: 0.3rem;
    overflow-wrap: break-word;
    font-size: 1.51rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    color: #737373;
  }
  .car-msg {
    margin-bottom: 3rem;
  }
  .right-box {
    margin-top: 5.34rem;
  }
  .left-box {
    border-right: 1px solid #999;
    padding-right: 1.55rem;
  }
  .img-box2 {
    width: 10.07rem;
    height: 10.07rem;
  }
  .img-QR {
    width: 100%;
  }
  .address {
    margin: 0.75rem 0 0.93rem;
    overflow-wrap: break-word;
    color: rgba(114, 114, 114, 1);
    font-size: 2.05rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    opacity: 1;
  }
  .eng-title {
    overflow-wrap: break-word;
    color: rgba(180, 0, 255, 1);
    font-size: 3.54rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    margin: 0.614rem 0 0 0.027rem;
  }
  .big-title {
    overflow-wrap: break-word;
    color: rgba(180, 0, 255, 1);
    font-size: 6.86rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    font-weight: 400;
  }
  .way {
    padding: 4.04rem 1.1rem 3.7rem;
    background: #e9e9e9;
    display: flex;
    flex-direction: column;
  }
  .logo-box {
    width: 10.67rem;
    height: 10.67rem;
    border-radius: 50%;
    border: 0.08rem solid #000;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img1 {
    width: 5.07rem;
    height: 3.97rem;
  }
  .img2 {
    width: 3.9rem;
    height: 3.9rem;
  }
  .img3 {
    width: 3.84rem;
    height: 3.49rem;
  }
  .content {
    padding: 4.79rem 1.1rem 0;
  }
  .title {
    text-align: center;
    overflow-wrap: break-word;
    color: rgba(20, 20, 20, 1);
    font-size: 2.05rem;
    font-family: MicrosoftYaHei;
    white-space: nowrap;
    line-height: 0.587rem;
    margin: 4.45rem 0 0.68rem;
  }
  .msg {
    overflow-wrap: break-word;
    color: rgba(123, 121, 121, 1);
    font-size: 1.64rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    margin: 0.4rem auto 0;
  }
}
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .input-group {
    display: flex;
    justify-content: space-between;
  }
  .box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .submit {
    margin-right: 1rem;
    background-color: #b400ff;
    color: #fff;
  }
  .submit-btn {
    margin: 0.8rem auto;
  }
  .textarea {
    border: 1px solid #eee;
    box-shadow: 0px 2px 16px 2px rgb(7 0 2 / 5%);
  }
  .left-group {
    width: 15.4rem;
  }
  .right-group {
    width: 14.85rem;
  }
  .label {
    color: #999797;
  }
  html {
    font-size: 37.5px;
  }
  .input {
    border-bottom: 1px solid #eee;
    width: 15.33rem;
    margin-bottom: 0.33rem;
  }
  .advise {
    padding: 1.52rem 9.6rem;
  }
  .advise-title {
    overflow-wrap: break-word;
    color: rgba(60, 60, 60, 1);
    font-size: 1.28rem;
    font-family: MicrosoftYaHei;
    text-align: center;
    white-space: nowrap;
    line-height: 1.28rem;
    margin-bottom: 1.92rem;
  }
  .car-title,
  .underground-title {
    color: #333;
    font-size: 0.64rem;
  }
  .car-msg,
  .underground-msg {
    margin-top: 0.3rem;
    width: 16.027rem;
    overflow-wrap: break-word;
    font-size: 0.6rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    color: #737373;
    line-height: 0.78rem;
  }
  .right-box {
    padding-left: 2.45rem;
  }
  .left-box {
    border-right: 1px solid #999;
    padding-right: 1.55rem;
  }
  .img-box2 {
    width: 2.347rem;
    height: 2.347rem;
  }
  .img-QR {
    width: 100%;
  }
  .address {
    margin: 0.75rem 0 0.93rem;
    overflow-wrap: break-word;
    color: rgba(114, 114, 114, 1);
    font-size: 0.48rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 0.587rem;
    opacity: 1;
  }
  .eng-title {
    overflow-wrap: break-word;
    color: rgba(180, 0, 255, 1);
    font-size: 0.826rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    white-space: nowrap;
    line-height: 0.587rem;
    margin: 0.614rem 0 0 0.027rem;
  }
  .big-title {
    height: 1.6rem;
    line-height: 1.6rem;
    overflow-wrap: break-word;
    color: rgba(180, 0, 255, 1);
    font-size: 1.6rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    font-weight: 400;
  }
  .way {
    padding: 1.17rem 9.6rem 1.2rem;
    background: #e9e9e9;
    display: flex;
  }
  .logo-box {
    width: 4.15rem;
    height: 4.15rem;
    border-radius: 50%;
    border: 0.08rem solid #000;
    margin: 0 auto;
  }

  .img1 {
    width: 1.97rem;
    height: 1.55rem;
    margin: 1.25rem 1.02rem;
  }
  .img2 {
    width: 1.52rem;
    height: 1.52rem;
    margin: 1.25rem;
  }
  .img3 {
    width: 1.49rem;
    height: 1.36rem;
    margin: 1.35rem 1.25rem;
  }
  .content {
    padding: 1.71rem 9.6rem 2.19rem;
  }
  .title {
    text-align: center;
    overflow-wrap: break-word;
    color: rgba(20, 20, 20, 1);
    font-size: 0.8rem;
    font-family: MicrosoftYaHei;
    white-space: nowrap;
    line-height: 0.587rem;
    margin-top: 1.73rem;
  }
  .msg {
    overflow-wrap: break-word;
    color: rgba(123, 121, 121, 1);
    font-size: 0.533rem;
    font-family: MicrosoftYaHei;
    text-align: left;
    line-height: 0.934rem;
    margin-top: 0.4rem;
  }
}
</style>
