<template>
  <div class="page">
    <imgHeader
      title="新闻资讯"
      msg="NEWS INFORMATION"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/3068af9f-4152-4fae-a8c9-0bca2f26729f矩形 1@2x (3).png"
      v-if="device === 'PC'"
    />
    <imgHeader
      title="新闻资讯"
      msg="NEWS INFORMATION"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/0b2db741-99c9-44da-8ee1-a1f4d399dc2b矩形 1@2x (10).png"
      v-else
    />
    <div class="content-header">
      <div class="left-box" @click="navigateToHome">
        <el-button type="text" icon="el-icon-arrow-left"></el-button>
        <el-button type="text" icon="el-icon-s-home">首页</el-button>
      </div>
    </div>
    <div class="content">
      <div class="content-body flex-row justify-between">
        <div class="left flex-col justify-between">
          <div class="QR-title">安惠传媒</div>
          <div class="title2-box flex-row justify-between">
            <div class="border-box"></div>
            <div class="title2">安徽传媒微信公众号</div>
            <div class="border-box"></div>
          </div>
          <div class="img-QR">
            <img
              class="img"
              src="https://s1.ax1x.com/2022/11/04/xLNGb4.png"
              alt=""
            />
          </div>
        </div>
        <div class="right">
          <div class="news-title">{{ details.newsTitle }}</div>
          <div class="news-date">{{ details.createTime }}</div>
          <el-divider></el-divider>
          <div class="news-content" v-html="details.newsContent"></div>
          <div class="img-box">
            <img class="img" :src="details.newsCover" alt="details.keywords" />
          </div>
        </div>
      </div>
    </div>
    <!-- 二维码地址 -->
    <!-- https://anhuiguanwang.oss-cn-shenzhen.aliyuncs.com/anhui/2022-11-25/c8210fcc-d246-4416-a405-2f6af74e6856aa.png -->
    <!-- <div class="content">
      <div class="img-box">
        <img class="img" :src="details.anhuiCaseCover" alt="" />
      </div>
      <div class="title">{{ details.anhuiCaseTitle }}</div>
      <div class="msg">{{ details.anhuiCaseContent }}</div>
    </div> -->
    <myFooter />
  </div>
</template>
<script>
import imgHeader from '../../components/img-header.vue'
import myFooter from '../../components/my-footer.vue'
import { getNewsDetails } from '@/api/index.js'
export default {
  data() {
    return {
      details: {},
    }
  },
  methods: {
    navigateToHome() {
      this.$router.push('/')
    },
  },
  computed: {
    device() {
      return this.$store.state.userDevice
    },
  },
  components: {
    imgHeader,
    myFooter,
  },
  beforeCreate() {
    getNewsDetails(this.$route.query.newsId).then(({ data }) => {
      this.details = data.data
      console.log(data.data)
    })
  },
}
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 480px) {
  .content-body {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 4.32rem 0 0;
  }

  .news-title {
    font-size: 2.47rem;
    color: #2e2e2e;
  }
  .news-date {
    font-size: 1.64rem;
    color: #353535;
  }
  .title2-box {
    margin: 0.69rem 0 0.8rem;
  }
  .border-box {
    background-color: #555;
    width: 1.44rem;
    height: 1px;
    margin: 0.37rem;
  }
  .left {
    padding: 1.39rem 1rem 2.16rem;
    align-items: center;
    box-shadow: 0px 0px 5px 5px rgba(240, 240, 240, 0.7);
  }
  .news-content {
    margin: 0 0 1.2rem;
    font-size: 1.64rem;
    color: #706f6f;
  }
  .right {
    padding: 4.45rem 1.27rem 5.21rem;
    box-shadow: 0px 0px 5px 5px rgba(240, 240, 240, 0.7);
    margin-bottom: 1.03rem;
  }
  .left-box:hover {
    cursor: pointer;
  }

  .QR-title {
    font-size: 2.47rem;
    text-align: center;
  }
  .img-QR {
    width: 13.84rem;
    height: 13.84rem;
    border: 1px #555 dashed;
  }
  .title2 {
    font-size: 1.23rem;
  }
  .content {
    padding: 0rem 1.1rem 9.66rem;
  }
  .img-box {
    width: 100%;
  }
  .img {
    width: 100%;
  }
  .content-header {
    background-color: #dedede;
  }
  .el-button--text {
    color: #000 !important;
  }
}
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .content-header {
    padding: 0 9.6rem;
  }
  .content-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .news-title {
    font-size: 0.8rem;
  }
  .news-date {
    font-size: 0.48rem;
  }
  .title2-box {
    margin: 0.69rem 0 0.8rem;
  }
  .border-box {
    background-color: #555;
    width: 1.44rem;
    height: 1px;
    margin: 0.37rem;
  }
  .left {
    width: 10.72rem;
    height: 11.84rem;
    padding: 1.39rem 1rem 2.16rem;
    align-items: center;
    box-shadow: 0px 0px 5px 5px rgba(240, 240, 240, 0.9);
  }
  .news-content {
    margin: 0 0 1.2rem;
    font-size: 0.53rem;
    color: #706f6f;
  }
  .right {
    width: 20.89rem;
    padding: 1.05rem;
    box-shadow: 0px 0px 5px 5px rgba(240, 240, 240, 0.9);
  }
  .left-box:hover {
    cursor: pointer;
  }

  .QR-title {
    font-size: 0.96rem;
    text-align: center;
  }
  .img-QR {
    width: 5.39rem;
    height: 5.39rem;
    border: 1px #555 dashed;
  }
  .title2 {
    font-size: 0.48rem;
  }
  .content {
    padding: 1.71rem 9.6rem 2.69rem;
  }
  .img-box {
    width: 100%;
  }
  .img {
    width: 100%;
  }
}
</style>
