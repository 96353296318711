<template>
  <div class="qiyehezuo flex-col">
    <item-title title="企业合作" msg="COOPERATION"></item-title>
    <div class="box_43">
      <div class="image-wrapper_1 flex-col" v-for="item in list" :key="item.id">
        <img class="image_8" referrerpolicy="no-referrer" :src="item.url" />
      </div>
    </div>
  </div>
</template>
<script>
import itemTitle from "./item-title.vue";
export default {
  name: "cooperation",
  data() {
    return {
      list: [
        {
          url: "https://s1.ax1x.com/2022/11/04/xLNta9.png",
          id: 1,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLNYVJ.png",
          id: 2,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLN8rF.png",
          id: 3,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLNta9.png",
          id: 4,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLNYVJ.png",
          id: 5,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLN8rF.png",
          id: 6,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLNta9.png",
          id: 7,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLNYVJ.png",
          id: 8,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLN8rF.png",
          id: 9,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLNta9.png",
          id: 10,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLNYVJ.png",
          id: 11,
        },
        {
          url: "https://s1.ax1x.com/2022/11/04/xLN8rF.png",
          id: 12,
        },
      ],
    };
  },
  components: {
    itemTitle,
  },
};
</script>
<style scoped>
@media not screen and (min-width: 320px) and (max-width: 480px) {
  .qiyehezuo {
    padding: 3.12rem 0 3.3rem;
    /* background-color: #444; */
  background-color: #000;
  }

  .box_43 {
    width: 32.92rem;
    margin: 1.28rem auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .image-wrapper_1 {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.824rem;
    height: 1.627rem;
    width: 4.694rem;
    margin: 0.6rem 0.3rem 0 0.3rem;
  }

  .image_8 {
    width: 3.04rem;
    height: 1.014rem;
    margin: 0.294rem 0 0 0.907rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .qiyehezuo {
    padding: 3.12rem 1.1rem 3.3rem;
    background-color: #000;
  }

  .box_43 {
    margin: 1.28rem auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .image-wrapper_1 {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 2rem;
    height: 5.06rem;
    width: 30%;
    margin-bottom: 1.37rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image_8 {
    width: 9.52rem;
    height: 3.15rem;
  }
}
</style>
