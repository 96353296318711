<template>
  <div id="app">
    <myHeader />
    <router-view />
  </div>
</template>

<script>
import myHeader from './components/my-header.vue'

export default {
  data() {
    return {}
  },
  computed: {},
  created() {
    this.$store.commit('setUserDevice')
  },
  components: {
    myHeader,
  },
}
</script>

<style lang="css" src="./assets/common.css"></style>
