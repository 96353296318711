<template>
  <div class="page">
    <imgHeader
      title="案例展示"
      msg="CASE PRESENTATION"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/2f8b5dda-af30-4016-9b84-1cd28434d41e矩形 1@2x (2).png"
      v-if="device === 'PC'"
    />
    <imgHeader
      title="案例展示"
      msg="CASE PRESENTATION"
      img="https://anhuiguanwang1.oss-cn-shenzhen.aliyuncs.com/anhui/2023-05-09/53b38ca9-575d-4053-a2e3-be3cdfd7e5561.png"
      v-else
    />
    <div class="content">
      <div class="content2">
        <div class="btn-box">
          <switch-btn
            class="switch-btn-component"
            v-for="item in caseTypeList"
            :key="item.caseTypeName"
            :title="item.caseTypeName"
            @switch-click="handleSwitchClick(item.caseTypeId)"
          ></switch-btn>
        </div>
        <div class="item-container flex-row justify-between">
          <div class="list1-group">
            <div
              class="item"
              v-for="item in list1"
              :key="item.anhuiCaseId"
              ref="items"
              @click="navigateToDetails(item.anhuiCaseId)"
            >
              <img class="img-item" :src="item.anhuiCaseCover" :alt="item.keywords" />
            </div>
          </div>
          <div class="list2-group">
            <div
              class="item"
              v-for="item in list2"
              :key="item.anhuiCaseId"
              ref="items2"
              @click="navigateToDetails(item.anhuiCaseId)"
            >
              <img class="img-item" :src="item.anhuiCaseCover" :alt="item.keywords" />
            </div>
          </div>
          <div class="list3-group" v-if="device === 'PC'">
            <div
              class="item"
              v-for="item in list3"
              :key="item.anhuiCaseId"
              ref="items3"
              @click="navigateToDetails(item.anhuiCaseId)"
            >
              <img class="img-item" :src="item.anhuiCaseCover" :alt="item.keywords" />
            </div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="changePage"
          :current-page="page"
          :page-size="12"
        ></el-pagination>
      </div>
    </div>
    <myFooter />
  </div>
</template>
<script>
import myFooter from "../../components/my-footer.vue";
import imgHeader from "../../components/img-header.vue";
import switchBtn from "../../components/switch-btn.vue";
import { getCaseTypeList, getCaseListByType } from "@/api/index.js";

export default {
  data() {
    return {
      caseTypeList: [],
      bigImg: [],
      middleImg: [],
      smallImg: [],
      list1: [],
      list2: [],
      list3: [],
      height_1: 0,
      height_2: 0,
      height_3: 0,
      page: 1,
      total: 20,
      type: 0,
    };
  },
  computed: {
    device() {
      return this.$store.state.userDevice;
    },
  },
  methods: {
    handleSwitchClick(caseTypeId) {
      this.getList(caseTypeId,1);
      this.page=1
    },
    changePage(pageNum) {
      this.page = pageNum;
      this.getList(this.type, pageNum)
    },
    navigateToDetails(caseId) {
      this.$router.push("/caseDetails?anhuiCaseId=" + caseId);
    },
    getList(id, pageNum=1) {
      this.total = 0
      this.type = id;
      getCaseListByType(id, pageNum).then(({ data }) => {
        this.total = data.total;
        this.list1 = [];
        this.list2 = [];
        this.list3 = [];
        this.height_1 = 0;
        this.height_2 = 0;
        this.height_3 = 0;
        if (this.device === "PC") {
          for (let i = 0; i < data.caseList.length; i++) {
            if (this.height_1 <= this.height_2 && this.height_1 <= this.height_3) {
              this.list1.push(data.caseList[i]);
              this.height_1 += data.caseList[i].coverHeight;
            } else if (this.height_2 <= this.height_1 && this.height_2 <= this.height_3) {
              this.list2.push(data.caseList[i]);
              this.height_2 += data.caseList[i].coverHeight;
            } else {
              this.list3.push(data.caseList[i]);
              this.height_3 += data.caseList[i].coverHeight;
            }
          }
        } else {
          for (let i = 0; i < data.caseList.length; i++) {
            if (this.height_1 <= this.height_2) {
              this.list1.push(data.caseList[i]);
              this.height_1 += data.caseList[i].coverHeight;
            } else {
              this.list2.push(data.caseList[i]);
              this.height_2 += data.caseList[i].coverHeight;
            }
          }
          console.log("this.height_1", this.height_1);
          console.log("this.height_2", this.height_2);
        }
      });
    },
    getTypeList() {
      return getCaseTypeList().then(({ data }) => {
        this.caseTypeList = data.caseTypeList;
      });
    },
  },
  created() {
    this.getTypeList();
  },
  mounted() {
    this.getList(1);
  },
  components: {
    switchBtn,
    myFooter,
    imgHeader,
  },
};
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 480px) {
  .list1-group,
  .list2-group,
  .list3-group {
    width: 49.5%;
    background-color: #000;
  }
  .list2-group {
    background-color: #000;
  }

  .content {
    padding: 3.29rem 1.1rem 0;
  }

  .switch-btn-component {
    color: #000;
    height: 3.22rem;
  }
  .btn-box {
    margin: 0 auto 1.85rem;
    display: flex;
    justify-content: space-between;
  }
  .img-item {
    width: 100%;
    background-color: #000;
  }
  .pagination {
    /* padding: 0 22rem; */
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
@media not screen and (min-width: 320px) and (max-width: 480px) {

@keyframes big {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.02);
  }
}
  .item:hover{

    animation-name: big;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  .pagination {
    /* padding: 0 22rem; */
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .page {
    background-color: #000;
  }
  .list1-group,
  .list2-group,
  .list3-group {
    width: 32%;
  }
  .item-container {
    padding-bottom: 10rem;
  }

  .switch-btn-component {
    color: #000;
  }
  .btn-box {
    width: 32rem;
    margin: 0 auto 0.67rem;
    padding-top: 1.36rem;
  }
  .img-item {
    width: 100%;
  }

  .content {
    padding: 0rem 8rem 0rem;
    background-color: #000;
  }
  .content2 {
    padding: 0 1.6rem 0rem;
    background-color: rgb(247, 247, 247);
  }
}
</style>
